import {createGlobalStyle} from 'styled-components';
import {device} from './device';
const GlobalStyle = createGlobalStyle`


    body{
        font-family: 'Open Sans', sans-serif;
        font-weight: 400;

        .ReactModal__Overlay{
            z-index:9999 !important;
        }
    }

    h1,h2,h3,h4,h5{
        font-family: 'Merriweather', serif;
        font-weight: 400;
    }
    
    h6,p{
        font-family: 'Open Sans', sans-serif;
        font-weight: 400;
        color:#fff;
        margin-bottom:20px;
        font-size:14px;
        line-height:1.5;
    }
    a{
        font-size:16px;
        font-family: 'Open Sans', sans-serif;
        // font-weight:600;
    }
    img{
        margin-bottom:0px;
    }
    h1{
        font-size:62px;
        line-height:1.2;
        margin-bottom:20px;
        color:#fff;
        text-align:center;
    
        @media ${device.laptop} {
            font-size:54px;
            line-height:1.2;
        }
        
        @media ${device.mobileXL} {
            font-size:42px;
            line-height:1.2;
        }
    }
    h2{
        font-size: 40px;
        line-height:46px;
        margin-bottom:20px;
        color:#fff;
        text-align:center;
    
        @media ${device.tablet} {
            font-size:38px;
            line-height:44px;
        }
    }
    h3{
        font-size: 36px;
        line-height:42px;
        margin-bottom:20px;
        color:#fff;
        text-align:center;
    }
    h4{
        font-size:30px;
        line-height:36px;
        margin-bottom:20px;
        color:#fff;
        text-align:center;
    
        @media ${device.tablet} {
            font-size:28px;
            line-height:32px;
        }
    }
    h5{
        font-size:22px;
        line-height:28px;
        margin-bottom:20px;
        color:#fff;
        text-align:center;
    }
    h6{
        font-size:18px;
        line-height:24px;
        margin-bottom:20px;
        color:#fff;
        text-align:center;
        font-weight:600;
    
        @media ${device.mobileXL} {
            font-size:17px;
            line-height:23px;
        }
    }
`;

export default GlobalStyle;