import styled from 'styled-components';
import {device} from './device';

export const Commonbtn = styled.a`
    background: #f4ba10;
    color: #000;
    padding: 8px 40px;
    text-decoration:none;
    display:inline-block;
    text-transform:uppercase;
    cursor:pointer;

    :hover{
        background:#c39409;
        text-decoration:none;
        color:#fff;
    }

    @media ${device.mobileXL} {
        padding: 8px 30px;
    }
`;


export const CommonMerriWh6 = styled.h6`
    font-size:18px;
    line-height:24px;
    margin-bottom:20px;
    color:#fff;
    text-align:center;
    font-family: 'Merriweather', serif;
    font-weight:600;

    @media ${device.mobileXL} {
        font-size:17px;
        line-height:23px;
    }
`;

export const SectionHeading = styled.h2`
    text-align:center;
    line-height:1;
    color:#fff;

    @media ${device.tablet} {
        line-height:1;
    }
`;

export const SectionSubHeading = styled.h6`
    line-height:1;
    text-align:center;
    color:#fff;
    margin-bottom:10px;

    @media ${device.mobileXL} {
        line-height:1;
    }
`;